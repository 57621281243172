export enum ChangeType {
  Fix = "fix",
  Feature = "feature",
  Improvement = "improvement"
}
export class ChangeTypeType {
  label: string;
  value: ChangeType;
  static types: ChangeTypeType[] = [
    { value: ChangeType.Fix, label: 'fix' },
    { value: ChangeType.Feature, label: 'feature' },
    { value: ChangeType.Improvement, label: 'improvement' }
  ]
  static mapType(type: ChangeType): string {
    return this.types.filter(t => t.value == type).map<string>(f => f.label)[0];
  }
}
